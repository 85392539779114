import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useState } from "react";
import { navigate } from "gatsby";
import BusinessPricing from "./BusinessPricing";
import Button from "../Button/ButtonSolid";

const PricingTool = () => {
  const [step, setStep] = useState();
  const [selectHistory, setHistory] = useState([4, 5]);
  const [lastStep, setLastStep] = useState();
  const [state, setState] = useState({});
  const [total, setTotal] = useState(0);
  const [submissionStatus, setSubmissionStatus] = useState([]);
  const [formStatus, setFormStatus] = useState(false);
  const [formCalanderyStatus, setFormCalanderyStatus] = useState(false);
  const [changeCategory, setChangeCategory] = useState(false);
  const [discoveryCallClicked, setDiscoveryCallClicked] = useState(false);

  const [lowEndRange, setLowEndRange] = useState(0);
  const [highEndRange, setHighEndRange] = useState(0);

  useEffect(() => {
    if (submissionStatus.includes("errored")) {
      setSubmissionStatus([]);
      return;
    }
    const [, ...compareArray] = submissionStatus;
    if (
      compareArray.length &&
      compareArray.every((status) => status === "success")
    ) {
      setState((prevState) => ({
        ...prevState,
        4: "Book Consultation",
      }));
      setSubmissionStatus([]);
    }
  }, [submissionStatus, state]);

  const {
    Bookkeeping: bookkeeping = {},
    "Tax Preparation": taxPrep = {},
    "Fractional CFO": fractionalCFO = {},
  } = state;
  const {
    "K1 Forms": k1Forms,
    "State Anticipate": stateAnticipate,
    Revenue: revenue,
    Shareholders: shareholders,
  } = taxPrep;
  const {
    "Accounting Basis": accountingBasis,
    "Connected Accounts": connectedAccounts,
    "Bill Pay": billPay,
    Payroll: payroll,
    "Annual Revenue": annualRevenue,
  } = bookkeeping;

  const { "Business Type": businessType } = taxPrep;

  const {
    "Budget Creation And Analysis": budgetCreationAndAnalysis,
    "Statement Of Cash Flows": statementOfCashFlows,
    "Financial Forecast": financialForecast,
    "Internal Controls Design and Implementation": internalControls,
    "KPI Monitoring and Reporting": KPIMonitoring,
    "Monthly Flux Analysis": monthlyFluxAnalysis,
    "Operational Process Advisory": OperationalProcessAdvisory,
    "Technology Advisory": technologyAdvisory,
    "Strategic Advisory Meetings": strategicAdvisoryMeetings,
  } = fractionalCFO;

  const toggleChangeCategory = () => {
    setChangeCategory(!changeCategory);
  };

  useEffect(() => {
    if (
      (businessType === "Individual" || businessType === "Business") &&
      !Object.values(state).some((node) =>
        ["Bookkeeping", "Fractional CFO", "Tax Planning"].includes(node)
      )
    ) {
      setChangeCategory(true);
      return;
    }
    setChangeCategory(false);
  }, [businessType, state]);

  useEffect(() => {
    setTotal(0);
    setLowEndRange(0);
    setHighEndRange(0);

    const {
      "Tax Preparation": taxPrep = {},
      "Tax Planning": taxPlan = {},
      Bookkeeping: bookkeeping = {},
      "Fractional CFO": fractionalCFO = {},
    } = state;
    const {
      "Business Type": businessType,
      "Filling Status": fillingStatus,
      "Own Home": ownHome,
      "K1 Forms": k1Forms,
      "State Anticipate": stateAnticipate,
      "Tax Structure": taxStructure,
      Revenue: revenue,
      Shareholders: shareholders,
    } = taxPrep;
    const { Interval: interval } = taxPlan;
    const {
      "Accounting Basis": accountingBasis,
      Integration: integration,
      "Connected Accounts": connectedAccounts,
      "Bill Pay": billPay,
      Payroll: payroll,
      Frequency: frequency,
      "Annual Revenue": annualRevenue,
      "Monthly Transactions": monthlyTransactions,
    } = bookkeeping;

    // 1st Breakpoint
    if (annualRevenue >= 0 && annualRevenue <= 250000) {
      const minBasePrice = 400;
      const maxBasePrice = 400;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 400;
        let maxMonthlyPriceRange = 400;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 800;
        let maxMonthlyPriceRange = 800;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 2nd Breakpoint
    if (annualRevenue >= 250001 && annualRevenue <= 500000) {
      const minBasePrice = 400;
      const maxBasePrice = 710;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 400;
        let maxMonthlyPriceRange =
          Math.round(((500000 - 250000) * (0.015 / 12) + 400) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 800;
        let maxMonthlyPriceRange =
          Math.round(((500000 - 250000) * (0.015 / 12) + 800) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 3rd Breakpoint
    if (annualRevenue >= 500001 && annualRevenue <= 1000000) {
      const minBasePrice = 710;
      const maxBasePrice = 1130;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 710;
        let maxMonthlyPriceRange =
          Math.round(((1000000 - 500000) * (0.01 / 12) + 710) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 1110;
        let maxMonthlyPriceRange =
          Math.round(((1000000 - 500000) * (0.01 / 12) + 1110) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 4th Breakpoint
    if (annualRevenue >= 1000001 && annualRevenue <= 2500000) {
      const minBasePrice = 1130;
      const maxBasePrice = 2130;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 1130;
        let maxMonthlyPriceRange =
          Math.round(((2500000 - 1000000) * (0.008 / 12) + 1130) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 1530;
        let maxMonthlyPriceRange =
          Math.round(((2500000 - 1000000) * (0.008 / 12) + 1530) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 5th Breakpoint
    if (annualRevenue >= 2500001 && annualRevenue <= 5000000) {
      const minBasePrice = 2130;
      const maxBasePrice = 3590;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 2130;
        let maxMonthlyPriceRange =
          Math.round(((5000000 - 2500000) * (0.007 / 12) + 2130) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 2530;
        let maxMonthlyPriceRange =
          Math.round(((5000000 - 2500000) * (0.007 / 12) + 2530) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 6th Breakpoint
    if (annualRevenue >= 5000001 && annualRevenue <= 10000000) {
      const minBasePrice = 3590;
      const maxBasePrice = 6090;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 3590;
        let maxMonthlyPriceRange =
          Math.round(((10000000 - 5000000) * (0.006 / 12) + 3590) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 3990;
        let maxMonthlyPriceRange =
          Math.round(((10000000 - 5000000) * (0.006 / 12) + 3990) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    // 7th Breakpoint
    if (annualRevenue >= 10000001 && annualRevenue <= 100000000) {
      const minBasePrice = "6090";
      const maxBasePrice = 43590;

      if (accountingBasis === "Cash") {
        let minMonthlyPriceRange = 6090;
        let maxMonthlyPriceRange =
          Math.round(((100000000 - 10000000) * (0.005 / 12) + 6090) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      } else if (accountingBasis === "Accrual") {
        let minMonthlyPriceRange = 6490;
        let maxMonthlyPriceRange =
          Math.round(((100000000 - 10000000) * (0.005 / 12) + 6490) / 10) * 10;

        if (frequency === "Monthly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 1) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 1) / 10) * 10;
        } else if (frequency === "Quarterly") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.9) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.9) / 10) * 10;
        } else if (frequency === "Annually") {
          minMonthlyPriceRange =
            Math.round((minMonthlyPriceRange * 0.8) / 10) * 10;
          maxMonthlyPriceRange =
            Math.round((maxMonthlyPriceRange * 0.8) / 10) * 10;
        }

        if (integration) {
          if (integration.includes("Square")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Paypal")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.25) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.25) / 10) *
              10;
          }
          if (integration.includes("Shopify")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Stripe")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.2) / 10) * 10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.2) / 10) * 10;
          }
          if (integration.includes("Etsy")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
          if (integration.includes("Zelle")) {
            minMonthlyPriceRange =
              Math.round((minMonthlyPriceRange + minBasePrice * 0.15) / 10) *
              10;
            maxMonthlyPriceRange =
              Math.round((maxMonthlyPriceRange + maxBasePrice * 0.15) / 10) *
              10;
          }
        }

        setLowEndRange(minMonthlyPriceRange);
        setHighEndRange(maxMonthlyPriceRange);
      }
    }

    switch (payroll) {
      case "0-20":
        setTotal((prevTotal) => prevTotal + 100);
        break;
      case "21-40":
        setTotal((prevTotal) => prevTotal + 200);
        break;
      case "41-60":
        setTotal((prevTotal) => prevTotal + 300);
        break;
    }

    switch (billPay) {
      case "0-20":
        setTotal((prevTotal) => prevTotal + 100);
        break;
      case "21-40":
        setTotal((prevTotal) => prevTotal + 200);
        break;
      case "41-60":
        setTotal((prevTotal) => prevTotal + 300);
        break;
    }

    switch (connectedAccounts) {
      case ">5":
      case "15+":
        break;
      case "5-9":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      case "10-14":
        setTotal((prevTotal) => prevTotal + 250);
        break;
    }

    switch (monthlyTransactions) {
      case "<25":
        break;
      case "25-49":
        setTotal((prevTotal) => prevTotal + 50);
        break;
      case "50-99":
        setTotal((prevTotal) => prevTotal + 200);
        break;
      case ">100":
        setTotal((prevTotal) => prevTotal + 400);
        break;
    }

    switch (integration) {
      case "Not Sure":
      case "Cash":
        break;
      case "Accrual":
        setTotal((prevTotal) => prevTotal + 350);
        break;
    }

    switch (interval) {
      case "Monthly":
        setTotal((prevTotal) => prevTotal + 250);
        break;
      case "Quarterly":
        setTotal((prevTotal) => prevTotal + 100);
        break;
      case "Bi-Annual":
        setTotal((prevTotal) => prevTotal + 70);
        break;
      case "Annually":
        setTotal((prevTotal) => prevTotal + 50);
        break;
    }

    switch (businessType) {
      case "Individual":
      case "Business":
      case "Both":
        break;
      default:
        break;
    }

    switch (fillingStatus) {
      case "Single":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      case "Head of Household":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      case "Married Filing Jointly":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      case "Married Filing Separately":
        setTotal((prevTotal) => prevTotal + 135);
        break;
      default:
        break;
    }

    switch (ownHome) {
      case "No":
        break;
      case "Yes":
        setTotal((prevTotal) => prevTotal + 21);
        break;
      default:
        break;
    }

    switch (k1Forms) {
      case "0":
        break;
      case "1":
        setTotal((prevTotal) => prevTotal + 8);
        break;
      case "2 - 5":
        setTotal((prevTotal) => prevTotal + 25);
        break;
      case "6 - 14":
        setTotal((prevTotal) => prevTotal + 50);
        break;
      case "15+":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      default:
        break;
    }

    switch (stateAnticipate) {
      case "0":
        break;
      case "1":
        break;
      case "2 - 5":
        setTotal((prevTotal) => prevTotal + 33);
        break;
      case "6 - 9":
        setTotal((prevTotal) => prevTotal + 50);
        break;
      case "10+":
        setTotal((prevTotal) => prevTotal + 100);
        break;
      default:
        break;
    }

    switch (taxStructure) {
      case "S Corporation":
        setTotal((prevTotal) => prevTotal + 208);
        break;
      case "C Corporation":
        setTotal((prevTotal) => prevTotal + 208);
        break;
      case "Multi-Member LLC":
        setTotal((prevTotal) => prevTotal + 208);
        break;
      case "SMLLC/Sole Proprietor":
        setTotal((prevTotal) => prevTotal + 125);
        break;
      case "Other":
        setTotal((prevTotal) => prevTotal + 208);
        break;
      default:
        break;
    }

    switch (revenue) {
      case "<$500k":
        break;
      case "$500K - $1M":
        setTotal((prevTotal) => prevTotal + 42);
        break;
      case "$1M - $5M":
        setTotal((prevTotal) => prevTotal + 83);
        break;
      case "$5M - $25M":
        setTotal((prevTotal) => prevTotal + 188);
        break;
      case "$25M+":
        setTotal((prevTotal) => prevTotal + 271);
        break;
      default:
        break;
    }

    switch (shareholders) {
      case "1":
        break;
      case "2 - 5":
        setTotal((prevTotal) => prevTotal + 38);
        break;
      case "6 - 9":
        setTotal((prevTotal) => prevTotal + 63);
        break;
      case "10 - 24":
        setTotal((prevTotal) => prevTotal + 113);
        break;
      case "25+":
        setTotal((prevTotal) => prevTotal + 188);
        break;
      default:
        break;
    }
  }, [state]);

  useEffect(() => {
    if (businessType === "Business") {
      let temp = JSON.parse(JSON.stringify(state));
      if (temp["Tax Preparation"].hasOwnProperty("Filling Status")) {
        delete temp["Tax Preparation"]?.["Filling Status"];
      }
      if (temp["Tax Preparation"].hasOwnProperty("State Anticipate")) {
        delete temp["Tax Preparation"]?.["State Anticipate"];
      }
      if (temp["Tax Preparation"].hasOwnProperty("Own Home")) {
        delete temp["Tax Preparation"]?.["Own Home"];
      }
      if (temp["Tax Preparation"].hasOwnProperty("K1 Forms")) {
        delete temp["Tax Preparation"]?.["K1 Forms"];
      }
      setState(temp);
    }

    if (businessType === "Individual") {
      let temp = JSON.parse(JSON.stringify(state));
      if (temp["Tax Preparation"].hasOwnProperty("Revenue")) {
        delete temp["Tax Preparation"]?.["Revenue"];
      }
      if (temp["Tax Preparation"].hasOwnProperty("Tax Structure")) {
        delete temp["Tax Preparation"]?.["Tax Structure"];
      }
      if (temp["Tax Preparation"].hasOwnProperty("Shareholders")) {
        delete temp["Tax Preparation"]?.["Shareholders"];
      }
      setState(temp);
    }
  }, [businessType]);

  const getLowerRange = () => {
    const grandTotal = total + lowEndRange;
    return Math.floor(grandTotal);
  };

  const getHigherRange = () => {
    const grandTotal = total + highEndRange;
    return Math.ceil(grandTotal);
  };

  const refCb = useCallback((node) => {
    if (node) {
      setFormStatus(true);
    } else {
      setFormStatus(false);
    }
  });

  const refCalandery = useCallback((node) => {
    if (node) {
      setFormCalanderyStatus(true);
      setFormStatus(true);
    } else {
      setFormCalanderyStatus(false);
    }
  });

  const twentyPerOfLowerRange = Math.round((getLowerRange() * 20) / 100);
  const newHigherRange = twentyPerOfLowerRange + getHigherRange();

  const yearlyNewHigherRange = newHigherRange * 12;

  const yearlyLowerRange = getLowerRange() * 12;
  const yearlyHigherRange = getHigherRange() * 12;

  return (
    <div>
      <div
        className={`relative  bg-[#000] py-2 lg:hidden ${
          step === selectHistory.length - 2 ||
          step === selectHistory.length - 1 ||
          discoveryCallClicked
            ? "hidden"
            : null
        }`}
      >
        <p className="mb-0 text-center text-xs font-normal text-white">
          Need a quote on all services? <br />
          <span
            onClick={() => {
              const secondLastElement = selectHistory[selectHistory.length - 2];
              setLastStep(step);
              setStep(selectHistory.indexOf(secondLastElement));
              setDiscoveryCallClicked(true);
            }}
            role="button"
            className="text-green-400"
          >
            Book a discovery call here.
          </span>
        </p>
        <StaticImage
          onClick={() => {
            navigate("/");
          }}
          role="button"
          src="../../images/business-pricing/cancel-icon-white.svg"
          className="!absolute right-4 top-[50%] translate-y-[-50%]"
        />
      </div>
      <div className="relative flex w-full justify-center border-b-[1px] border-b-gray-200 py-3 lg:py-6 ">
        <div className="flex w-fit  items-center gap-x-6 ">
          <div className="flex flex-col items-center lg:flex-row ">
            <StaticImage
              src="../../images/business-pricing/calulator-icon.svg"
              quality={100}
              className="mb-1 mr-0 h-6 w-6 lg:mb-0 lg:mr-2"
            />
            <p className="mb-0 text-center text-xs  font-semibold text-green-400 lg:text-left lg:text-base">
              Get Estimate
            </p>
          </div>
          <hr className="hidden h-[0px] w-[56px] rounded-[5px] border-[1.5px] border-green-400 lg:block" />
          <div
            className={`flex  flex-col items-center   lg:flex-row ${
              formStatus ? "opacity-100" : " opacity-40"
            }`}
          >
            {formStatus ? (
              <StaticImage
                src="../../images/business-pricing/share-green.svg"
                quality={100}
                className="mb-1 mr-0 h-6 w-6 lg:mb-0 lg:mr-2"
              />
            ) : (
              <StaticImage
                src="../../images/business-pricing/share-icon.svg"
                quality={100}
                className="mb-1 mr-0 h-6 w-6 lg:mb-0 lg:mr-2"
              />
            )}
            <p
              className={`mb-0 text-center text-xs  font-semibold  lg:text-left lg:text-base ${
                formStatus ? "text-green-400" : "text-black"
              } `}
            >
              Submit Details
            </p>
          </div>
          <hr className="hidden h-[0px] w-[56px] rounded-[5px] border-[1.5px] border-green-400 lg:block" />
          <div
            className={`flex  flex-col items-center lg:flex-row ${
              formCalanderyStatus ? " opacity-100" : " opacity-40 "
            }`}
          >
            {formCalanderyStatus ? (
              <StaticImage
                src="../../images/business-pricing/green-calender.svg"
                quality={100}
                className="mb-1 mr-0 h-6 w-6 lg:mb-0 lg:mr-2"
              />
            ) : (
              <StaticImage
                src="../../images/business-pricing/calender-icon.svg"
                quality={100}
                className="mb-1 mr-0 h-6 w-6 lg:mb-0 lg:mr-2"
              />
            )}
            <p
              className={`mb-0 text-center text-xs  font-semibold  lg:text-left lg:text-base ${
                formCalanderyStatus ? "text-green-400" : "text-black"
              }`}
            >
              Book Consultation
            </p>
          </div>
        </div>
        <StaticImage
          onClick={() => {
            navigate("/");
          }}
          role="button"
          src="../../images/business-pricing/cancel-icon.svg"
          quality={100}
          className="!absolute right-6 top-[50%] !hidden h-6 w-6 translate-y-[-50%] cursor-pointer lg:!block"
        />
      </div>

      <BusinessPricing
        total={total}
        state={state}
        step={step}
        setStep={setStep}
        lastStep={lastStep}
        setChangeCategory={setChangeCategory}
        selectHistory={selectHistory}
        setHistory={setHistory}
        discoveryCallClicked={discoveryCallClicked}
        setDiscoveryCallClicked={setDiscoveryCallClicked}
        lowerRange={getLowerRange()}
        higherRange={getHigherRange()}
        submissionStatus={submissionStatus}
        setSubmissionStatus={setSubmissionStatus}
        setState={setState}
        formStatus={formStatus}
        refCb={refCb}
        refCalandery={refCalandery}
        changeCategory={changeCategory}
      />

      <div
        className={`fixed bottom-0 z-10 w-full bg-[#000] ${
          Object.keys(state).find((item) => item === "4" || item === "5") &&
          "hidden"
        }`}
      >
        <div className="mx-auto flex w-full max-w-[1729px] items-center justify-between p-4 lg:px-20 lg:py-4 2xl:py-7">
          <p className="mb-0 hidden text-base font-normal text-white lg:block ">
            Need a quote on all services? &nbsp;
            <span
              onClick={() => {
                setState((prevState) => ({
                  4: "Book Consultation",
                }));
                setDiscoveryCallClicked(true);
              }}
              role="button"
              className="cursor-pointer font-semibold text-green-400 underline"
            >
              Book a discovery call here.
            </span>
          </p>
          <p className="mb-0 text-sm font-normal text-white lg:hidden">
            Your Estimate:
          </p>
          <div className="flex items-center">
            {connectedAccounts === "15+" ||
            k1Forms === "15+" ||
            stateAnticipate === "10+" ||
            revenue === "$25M+" ||
            shareholders === "25+" ||
            billPay === "61-80" ||
            payroll === "61-80" ||
            accountingBasis === "Not Sure" ||
            annualRevenue > 10000000 ||
            billPay === "61+" ||
            payroll === "61+" ||
            budgetCreationAndAnalysis === true ||
            statementOfCashFlows === true ||
            financialForecast === true ||
            internalControls === true ||
            KPIMonitoring === true ||
            monthlyFluxAnalysis === true ||
            OperationalProcessAdvisory === true ||
            technologyAdvisory === true ||
            strategicAdvisoryMeetings === true ? (
              <p className="mb-0 mr-auto flex items-center text-lg font-normal text-white lg:ml-4 lg:mr-20 lg:font-semibold">
                Contact us for a Custom Quote
              </p>
            ) : (
              <>
                {changeCategory ? (
                  <>
                    {getLowerRange() === getHigherRange() ? (
                      <span className="whitespace-nowrap text-xl font-bold text-white lg:text-4xl lg:leading-[34px] ">
                        {yearlyLowerRange ? `$${yearlyHigherRange} -` : ""} $
                        {yearlyNewHigherRange}
                      </span>
                    ) : (
                      <span className="whitespace-nowrap text-xl font-bold text-white lg:text-4xl lg:leading-[34px] ">
                        {getLowerRange() ? `$${yearlyLowerRange} -` : ""} $
                        {yearlyHigherRange}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {getLowerRange() === getHigherRange() ? (
                      <span className="whitespace-nowrap text-xl font-bold text-white lg:text-4xl lg:leading-[34px] ">
                        {getLowerRange() ? `$${getLowerRange()} -` : ""} $
                        {newHigherRange}
                      </span>
                    ) : (
                      <span className="whitespace-nowrap text-xl font-bold text-white lg:text-4xl lg:leading-[34px] ">
                        {getLowerRange() ? `$${getLowerRange()} -` : ""} $
                        {getHigherRange()}
                      </span>
                    )}
                  </>
                )}
                <p
                  onClick={toggleChangeCategory}
                  role="button"
                  className="mb-0 ml-2 mr-auto flex items-center gap-[2px] text-sm font-normal text-white lg:ml-4 lg:mr-10 lg:font-semibold"
                >
                  {changeCategory ? "per year" : "per month"}
                  <StaticImage
                    src="../../images/business-pricing/chevron-down.svg"
                    className="h-5 w-5 cursor-pointer"
                  />
                </p>
              </>
            )}
            <Button
              text="Get Started"
              altStyle={1}
              className="hidden bg-green-400 hover:!bg-[#448E62] lg:block"
              onClick={() => setSubmissionStatus(["submitting"])}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTool;
