import styled from "@emotion/styled";
import tw from "twin.macro";

export const StyledSlider = styled.div`
  .slider {
    ${tw`!w-[93%] sm:!w-[95%] lg:!w-[95%] h-auto! !mb-[2.5rem] mt-0.5 `}
  }
  .slider-track {
    ${tw`bg-green-400 opacity-30 rounded-lg bg-none! top-0! m-0! h-2!`}
  }
  .slider-tick-container {
    ${tw`top-0.5!`}
  }
  .slider-tick {
    ${tw`hidden`}
    &:first-child {
      ${tw`left-[-1%]!`}
    }
  }
  .slider-tick-label-container {
    ${tw`m-0! relative top-[2.2rem]`}
  }
  .slider-tick-label {
    ${tw`text-xs! text-gray-300! font-medium! w-auto! !ml-[1px]`}
  }
  .slider-handle {
    ${tw`w-10! h-10! relative shadow-6xl! bg-green-400! bg-none! !top-[-1.5rem] z-10! flex justify-between items-center p-1`}
    &.hide {
      ${tw`hidden`}
    }
    &::after {
      content: "";
      position: absolute;
      left: 9.2px;
      transform: translateY(-50%);
      top: 50%;
      width: 18.2px;
      height: 14.4px;
      clip-path: polygon(40% 0, 20% 50%, 40% 100%, 25% 100%, 0% 50%, 25% 0%);
      background: white;
    }
    &:before {
      content: "";
      position: absolute;
      right: 9.2px;
      transform: translateY(-50%) rotate(180deg);
      top: 50%;

      width: 18.2px;
      height: 14.4px;
      clip-path: polygon(40% 0, 20% 50%, 40% 100%, 25% 100%, 0% 50%, 25% 0%);
      background: white;
    }
  }
  .tick-slider-selection {
    ${tw`bg-none! bg-transparent! shadow-none!`}
  }
  .tooltip-main {
    ${tw`absolute mt-[-3.25rem]`}
    .tooltip-inner {
      ${tw`relative !left-[4px] bottom-[5px] -ml-6! bg-white! shadow-4xl! rounded-sm! text-gray-750 px-2.5! py-1!`}
      font-size: 11px !important;
      line-height: 15px !important;
      &:after {
        content: "";
        ${tw`absolute -bottom-1.5 inset-x-0 mx-auto h-0 w-0 border-8 border-b-0 border-transparent border-t-white`}
      }
    }
  }
  .slider-horizontal {
    .tooltip:nth-child(3) {
      display: none !important;
    }
    .tooltip:nth-child(4) {
      display: none !important;
    }
  }
`;
