import React, { useEffect } from "react";
import "./businesspricing.scss";

const ContactFormChart = ({
  lowerRange,
  higherRange,
  state,
  step,
  selectHistory,
  changeCategory,
  discoveryCallClicked,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Bookkeeping: bookkeeping = {}, "Tax Preparation": taxPrep = {} } =
    state;

  const {
    "K1 Forms": k1Forms,
    "State Anticipate": stateAnticipate,
    Revenue: revenue,
    Shareholders: shareholders,
  } = taxPrep;

  const {
    "Accounting Basis": accountingBasis,
    "Annual Revenue": annualRevenue,
    "Connected Accounts": connectedAccounts,
    "Bill Pay": billPay,
    Payroll: payroll,
  } = bookkeeping;

  const twentyPerOfLowerRange = Math.round((lowerRange * 20) / 100);
  const newHigherRange = twentyPerOfLowerRange + higherRange;

  const yearlyNewHigherRange = newHigherRange * 12;

  const yearlyLowerRange = lowerRange * 12;
  const yearlyHigherRange = higherRange * 12;

  return (
    <div
      className={`form_section absolute left-0 top-[117px] w-full bg-primary-50 lg:top-[73px] ${
        step === selectHistory.length - 1 || discoveryCallClicked
          ? "top-[69px]"
          : null
      }`}
    >
      <div className="mx-auto w-full max-w-[1728px] px-4  pb-20 lg:px-20 xl:px-[120px]">
        <div className="mx-auto flex flex-col items-center justify-center gap-12 lg:mx-0 lg:flex-row">
          <div className={`h-fit w-full max-w-[374px] self-center`}>
            <h2 className="mb-10 font-heading text-3xl font-bold lg:text-5xl lg:leading-[58px]">
              Book Your Free Consultation
            </h2>
            {accountingBasis === "Accrual" ||
            accountingBasis === "Not Sure" ||
            annualRevenue > 10000000 ||
            billPay === "61+" ||
            payroll === "61+" ||
            connectedAccounts === "15+" ||
            k1Forms === "15+" ||
            stateAnticipate === "10+" ||
            revenue === "$25M+" ||
            shareholders === "25+" ||
            discoveryCallClicked ? (
              <h2 className="mx-auto mb-4 flex w-fit items-end gap-2 font-body text-xl font-semibold text-gray-750 lg:text-4xl">
                Custom Qoute
              </h2>
            ) : (
              <>
                <h4 className="mb-3 text-base font-semibold text-gray-700 opacity-50">
                  Your Estimate
                </h4>
                {changeCategory ? (
                  <h2 className="mb-10 flex items-end gap-2 font-body text-4xl font-semibold text-gray-750">
                    {lowerRange === higherRange
                      ? `$${yearlyHigherRange} - $${yearlyNewHigherRange}`
                      : `$${yearlyLowerRange} - $${yearlyHigherRange}`}
                    <span className="font-body text-base leading-7 text-primary-700">
                      /year
                    </span>{" "}
                  </h2>
                ) : (
                  <h2 className="mb-10 flex items-end gap-2 font-body text-4xl font-semibold text-gray-750">
                    {lowerRange === higherRange
                      ? `$${higherRange} - $${newHigherRange}`
                      : `$${lowerRange} - $${higherRange}`}
                    <span className="font-body text-base leading-7 text-primary-700">
                      /month
                    </span>{" "}
                  </h2>
                )}
              </>
            )}
            <p className="text-base font-normal text-primary-700 ">
              After you select a day/time on the right, an automated calendar
              link will be sent to your email. Your Dark Horse CPA will meet
              with you via Zoom or phone, whichever you prefer. Thank you and we
              look forward to supporting you in your accounting needs!
            </p>
          </div>
          <div className={`w-full max-w-[746px]`}>
            <iframe
              src="https://meetings.hubspot.com/dark-horse/dark-horse-cannabis-meetings?embed=true"
              title="Book a meeting"
              width="100%"
              height="680px"
              border="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormChart;
