import React, { useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { StaticImage } from "gatsby-plugin-image";

import Bookkeeping from "./Bookkeeping";
import TaxPlanning from "./TaxPlanning";
import TaxPreparation from "./TaxPreparation";
import FractionalCFO from "./FractionalCFO";
import Button from "../Button/ButtonSolid";
import BookConsultation from "./ContactForm";
import ContactFormChart from "./ContactFormChart";

import toolTip from "../../images/business-pricing/tooltip-icon.svg";
import preparationImage from "../../images/business-pricing/tax-preparation.svg";
import planningImage from "../../images/business-pricing/tax-planning.svg";
import pricingImage from "../../images/business-pricing/book-keeping.svg";
import fractionalImage from "../../images/business-pricing/fractional-cfo.svg";
import selectTaxPreparationImage from "../../images/business-pricing/select-tax-preparation.svg";
import selectBookkeepingImage from "../../images/business-pricing/select-book-keeping.svg";
import selectFractionalCFOImage from "../../images/business-pricing/select-fractional-cfo.svg";
import selectTaxPlanning from "../../images/business-pricing/select-tax-planning.svg";

import "./businesspricing.scss";

const BusinessPricing = ({
  total,
  state,
  lastStep,
  step,
  setStep,
  setState,
  setHistory,
  selectHistory,
  discoveryCallClicked,
  setDiscoveryCallClicked,
  lowerRange,
  higherRange,
  submissionStatus,
  setSubmissionStatus,
  formStatus,
  refCb,
  refCalandery,
  changeCategory,
}) => {
  const [modalContent, setModalContent] = useState({});
  const [error, setError] = useState(false);

  const continueToNextStepHandler = () => {
    setError(true);
    if (Object.keys(state).length > 0) {
      setStep(0);
      setError(false);
    }
  };

  const toggleOption = (index, label) => {
    setState((prevState) => {
      if (prevState[index]) {
        delete prevState[index];
        delete prevState[label];
        return JSON.parse(JSON.stringify(prevState));
      }

      return {
        ...prevState,
        [index]: label,
        ...(label === "Bookkeeping" && {
          Bookkeeping: {
            "Accounting Basis": "Cash",
            "Annual Revenue": 1000,
            Frequency: "Monthly",
          },
        }),
      };
    });

    setHistory((prevState) => {
      if (prevState.includes(index)) {
        const res = prevState.filter((node) => node !== index);
        return [...res];
      }
      prevState.push(index);
      return [...prevState].sort((a, b) => a - b);
    });
  };

  const increaseStep = (e) => {
    e && e.preventDefault();
    if (React.isValidElement(steps[step + 1])) {
      setStep((prev) => prev + 1);
    }
  };

  const decreaseStep = () => {
    if (lastStep) {
      if (React.isValidElement(steps[lastStep - 1])) {
        return setStep(lastStep - 1);
      }
    }
    if (React.isValidElement(steps[step - 1])) {
      return setStep((prev) => prev - 1);
    }
    setStep();
  };

  const combinedProps = {
    total,
    state,
    step,
    selectHistory,
    setState,
    increaseStep,
    decreaseStep,
    submissionStatus,
    setSubmissionStatus,
    discoveryCallClicked,
    setDiscoveryCallClicked,
    lowerRange,
    higherRange,
    changeCategory,
  };

  const steps = {
    0: (
      <TaxPreparation
        customClass={formStatus ? "hidden" : "block"}
        {...combinedProps}
      />
    ),
    1: (
      <TaxPlanning
        customClass={formStatus ? "hidden" : "block"}
        {...combinedProps}
      />
    ),
    2: (
      <Bookkeeping
        customClass={formStatus ? "hidden" : "block"}
        {...combinedProps}
      />
    ),
    3: (
      <FractionalCFO
        customClass={formStatus ? "hidden" : "block"}
        {...combinedProps}
      />
    ),
    4: (
      <div ref={refCb}>
        <BookConsultation {...combinedProps} />
      </div>
    ),
    5: (
      <div ref={refCalandery}>
        <ContactFormChart {...combinedProps} />
      </div>
    ),
  };

  const options = [
    {
      label: "Tax Preparation",
      image:
        state[0] === "Tax Preparation"
          ? selectTaxPreparationImage
          : preparationImage,
      infoText:
        " The estimate does not include all elements that might impact the final price, such as multi-state filings, more than 1 owner/partner, additional K1s on the individual etc. ",
    },
    {
      label: "Tax Planning",
      image: state[1] === "Tax Planning" ? selectTaxPlanning : planningImage,
      infoText:
        "Tax Planning is customized for each individual based on current and projected earnings and/or equity events. Strategies vary along with the fee structure to execute on strategies provided.",
    },
    {
      label: "Bookkeeping",
      image: state[2] === "Bookkeeping" ? selectBookkeepingImage : pricingImage,
      infoText:
        'The estimate does not include all elements that might impact the final price, such as total number of transactions each month, multiple 3rd party integrations and reconciliations, and if the current books are "up to date". ',
    },
    {
      label: "Fractional CFO",
      image:
        state[3] === "Fractional CFO"
          ? selectFractionalCFOImage
          : fractionalImage,
      infoText:
        "The estimate does not include all elements that might impact the final price, such as: organized reporting, on-site meeting frequency, # of entities under management, securing debt etc.",
    },
  ];

  const stepRenderer = () => steps[selectHistory[step]];

  const isDesktop = useMediaPredicate("(min-width: 1024px)");
  const showFields =
    isDesktop &&
    Object.keys(state).some((key) => React.isValidElement(steps[key]));

  const tooltipButton = (e) => {
    const newData = options.find((data) => {
      return e.target.name === data.label;
    });
    setModalContent(newData);
  };

  return (
    <div className="business_contianer  mx-auto flex w-full max-w-[1728px] flex-col lg:flex-row">
      <div
        className={`tax_preparation w-full bg-primary-50 px-4 pt-[56px] lg:w-[50%] lg:px-20 lg:pt-[30px] lg:pb-[0px] 2xl:pt-[56px] 
        ${step === undefined ? "block" : "hidden"}  ${
          formStatus ? "lg:hidden" : "lg:block"
        }`}
      >
        <div>
          <p className="mb-2 hidden w-fit text-sm font-extrabold text-green-400 lg:block">
            Step 01
          </p>
          <h3 className="mb-3 font-heading text-3xl font-bold text-gray-750 lg:text-[30px]">
            Select Services
            {!Object.keys(state).length && (
              <span className="text-red-500">*</span>
            )}
          </h3>
          <p className="mb-8 text-base font-normal text-gray-700 lg:mb-5 2xl:mb-10">
            Select at least one service to begin. Note that the pricing
            displayed here is an estimate. We'll provide a formal quote once you
            meet with your CPA.
          </p>
          {error && (
            <div className="mb-4 text-red-500">
              <span>* </span>
              <span className="text-sm">
                Please select at least one service to continue.
              </span>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 pb-[170px] lg:gap-4 lg:pb-0 2xl:gap-6">
          {options.map(({ image, infoText, label }, index) => (
            <button
              className={`!relative flex flex-col  items-center rounded-md border-[1px] bg-white no-underline lg:py-3 2xl:py-6 ${
                state[index] === label
                  ? "border-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d]"
                  : "border-gray-200"
              }`}
              onClick={(event) => toggleOption(index, label)}
            >
              <input
                id="tax-prep"
                name="tax-prep"
                type="checkbox"
                checked
                className={`left-4  top-4 rounded-[4px] text-green-400 !outline-0 ${
                  state[index] === label ? "block !opacity-100" : "hidden"
                } `}
              />
              <img
                src={image}
                className="my-4 h-[64px] w-[64px] lg:mb-2 lg:mt-0"
              />
              <p className="mb-0 text-base font-medium text-gray-750 opacity-80 lg:text-xl  lg:leading-[20px]">
                {label}
              </p>

              <div
                onClick={(e) => e.stopPropagation()}
                className="  group  absolute right-4 top-4"
              >
                <button onClick={(e) => tooltipButton(e)}>
                  <img
                    name={label}
                    src={toolTip}
                    className="h-[18px] w-[18px] cursor-pointer"
                  />
                </button>
                <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                  {infoText}
                </p>
              </div>
            </button>
          ))}
        </div>
        {Object.keys(modalContent).length !== 0 && (
          <div className="absolute  top-0 left-0 z-20 flex  h-screen w-full items-center justify-center bg-[#00000080] px-[44px] lg:hidden">
            <div className="w-full rounded-md bg-white">
              <button
                className="float-right mr-3 mt-3 h-6  w-6 cursor-pointer "
                onClick={() => setModalContent({})}
              >
                <StaticImage
                  src="../../images/business-pricing/cancel-icon.svg"
                  quality={100}
                />
              </button>
              <img
                src={toolTip}
                className="mt-8 ml-4 h-[18px] w-[18px] cursor-pointer"
              />
              <h4 className="mt-3 mb-3 px-4 text-base font-semibold text-gray-750">
                {modalContent.label}
              </h4>

              <p className="mb-0 px-4 pb-8 text-base text-gray-700">
                {modalContent.infoText}
              </p>
            </div>
          </div>
        )}

        <Button
          text="Continue"
          altStyle={1}
          className="!absolute bottom-[100px] left-[50%] block w-[94%] translate-x-[-50%]  bg-green-400 hover:!bg-[#448E62] sm:w-[343px] lg:hidden"
          onClick={() => continueToNextStepHandler()}
        />
      </div>
      <div className="w-full overflow-y-auto pb-[62px] lg:w-[50%] lg:border-l-[1px] lg:border-l-gray-200 lg:px-8 xl:px-20">
        {showFields && (
          <>
            <p className="mb-2 hidden w-fit pt-[56px] text-sm font-extrabold  text-green-400 lg:block">
              Step 02
            </p>
            <h3 className="mb-3 hidden font-heading text-3xl font-bold text-gray-750 lg:block lg:text-[30px]">
              Add Some Additional Details
            </h3>
          </>
        )}
        {showFields ? (
          Object.keys(state).map(
            (key) => React.isValidElement(steps[key]) && steps[key]
          )
        ) : (
          <div className="mx-auto  hidden w-fit flex-col items-center lg:mt-[50px] lg:flex 2xl:mt-[153px]">
            <StaticImage
              src="../../images/business-pricing/vector-horse.svg"
              className="mx-auto h-full max-h-[307px] w-full max-w-[248px] "
            />
            <h5 className="mt-10 text-[36px] font-semibold text-gray-200">
              No Services Selected
            </h5>
          </div>
        )}
        {!isDesktop && stepRenderer()}
      </div>
    </div>
  );
};

export default BusinessPricing;
