import React, { useEffect, useState } from "react";
import Button from "../Button/ButtonSolid";
import { Switch } from "@headlessui/react";
import { StyledSlider } from "./styles";
import ReactBootstrapSlider from "react-bootstrap-slider";
import "./businesspricing.scss";

const BookConsultation = ({
  total,
  state,
  setState,
  discoveryCallClicked,
  setDiscoveryCallClicked,
  step,
  selectHistory,
  increaseStep,
  decreaseStep,
  lowerRange,
  higherRange,
  changeCategory,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [inputFields, setInputFields] = useState({});
  const [ownBusiness, setOwnBusiness] = useState(false);
  const [services, setServices] = useState("");
  const [errors, setErrors] = useState({});

  const {
    "Tax Preparation": taxPrep = {},
    "Tax Planning": taxPlan = {},
    Bookkeeping: bookkeeping = {},
    "Fractional CFO": fractionalCFO = {},
  } = state;
  const {
    "K1 Forms": k1Forms,
    "State Anticipate": stateAnticipate,
    Revenue: revenue,
    Shareholders: shareholders,
  } = taxPrep;
  const {
    "Accounting Basis": accountingBasis,
    "Annual Revenue": annualRevenue,
    "Bill Pay": billPay,
    Payroll: payroll,
    "Connected Accounts": connectedAccounts,
  } = bookkeeping;

  const { "Business Type": businessType } = taxPrep;

  const { [4]: steps, ...restState } = state;

  const showCustomQuote =
    !Object.keys(restState).length ||
    "Fractional CFO" in restState ||
    accountingBasis === "Accrual" ||
    accountingBasis === "Not Sure" ||
    annualRevenue > 10000000 ||
    billPay === "61+" ||
    payroll === "61+" ||
    connectedAccounts === "15+" ||
    k1Forms === "15+" ||
    stateAnticipate === "10+" ||
    revenue === "$25M+" ||
    shareholders === "25+";

  const twentyPerOfLowerRange = Math.round((lowerRange * 20) / 100);
  const newHigherRange = twentyPerOfLowerRange + higherRange;

  const yearlyNewHigherRange = newHigherRange * 12;

  const yearlyLowerRange = lowerRange * 12;
  const yearlyHigherRange = higherRange * 12;

  const answers = `
  Qoute:  ${
    showCustomQuote || discoveryCallClicked
      ? "Custom Quoute"
      : changeCategory
      ? lowerRange === higherRange
        ? `$${yearlyHigherRange} - $${yearlyNewHigherRange} /year`
        : `$${yearlyLowerRange} - $${yearlyHigherRange} /year`
      : lowerRange === higherRange
      ? `$${higherRange} - $${newHigherRange} /month`
      : `$${lowerRange} - $${higherRange} /month`
  } | \n
    Business Type: ${
      taxPrep["Business Type"] ? taxPrep["Business Type"] : "No"
    } | \n
    Filling Status: ${
      taxPrep["Filling Status"] ? taxPrep["Filling Status"] : "No"
    } | \n
    Own Home: ${taxPrep["Own Home"] ? taxPrep["Own Home"] : "No"} | \n
    K1 Forms: ${taxPrep["K1 Forms"] ? taxPrep["K1 Forms"] : "No"} | \n
    State Anticipate: ${
      taxPrep["State Anticipate"] ? taxPrep["State Anticipate"] : "No"
    } | \n
    Tax Structure: ${
      taxPrep["Tax Structure"] ? taxPrep["Tax Structure"] : "No"
    } | \n
    Revenue: ${taxPrep["Revenue"] ? taxPrep["Revenue"] : "No"} | \n
    Shareholders: ${
      taxPrep["Shareholders"] ? taxPrep["Shareholders"] : "No"
    } | \n
    Interval: ${taxPlan["Interval"] ? taxPlan["Interval"] : "No"} | \n
    Accounting Basis: ${
      bookkeeping["Accounting Basis"] ? bookkeeping["Accounting Basis"] : "No"
    } | \n
    Annual Revenue: ${
      bookkeeping["Annual Revenue"] ? bookkeeping["Annual Revenue"] : "No"
    } | \n
    Integration: ${
      bookkeeping["Integration"] ? bookkeeping["Integration"] : "No"
    } | \n
    Connected Accounts: ${
      bookkeeping["Connected Accounts"]
        ? bookkeeping["Connected Accounts"]
        : "No"
    } | \n
    Bill Pay: ${bookkeeping["Bill Pay"] ? bookkeeping["Bill Pay"] : "No"} | \n
    Payroll: ${bookkeeping["Payroll"] ? bookkeeping["Payroll"] : "No"} | \n
    Frequency: ${
      bookkeeping["Frequency"] ? bookkeeping["Frequency"] : "No"
    } | \n
    Budget Creation And Analysis: ${
      fractionalCFO["Budget Creation And Analysis"] ? "Yes" : "No"
    } | \n
    Statement Of Cash Flows: ${
      fractionalCFO["Statement Of Cash Flows"] ? "Yes" : "No"
    } | \n
    Financial Forecast: ${
      fractionalCFO["Financial Forecast"] ? "Yes" : "No"
    } | \n
    Internal Controls Design and Implementation: ${
      fractionalCFO["Internal Controls Design and Implementation"]
        ? "Yes"
        : "No"
    } | \n
    KPI Monitoring and Reporting: ${
      fractionalCFO["KPI Monitoring and Reporting"] ? "Yes" : "No"
    } | \n
    Monthly Flux Analysis: ${
      fractionalCFO["Monthly Flux Analysis"] ? "Yes" : "No"
    } | \n
    Operational Process Advisory: ${
      fractionalCFO["Operational Process Advisory"] ? "Yes" : "No"
    } | \n
    Technology Advisory: ${
      fractionalCFO["Technology Advisory"] ? "Yes" : "No"
    } | \n
    Strategic Advisory Meetings: ${
      fractionalCFO["Strategic Advisory Meetings"] ? "Yes" : "No"
    }\n
  `;

  const SERVICES = [
    "Individual Tax",
    "Business Tax",
    "Tax Planning",
    "Bookkeeping",
    "Fractional CFO",
  ];

  const checkForErrors = () => {
    let errors = {};
    if (ownBusiness && !inputFields.hasOwnProperty("business-name")) {
      errors["business-name"] = true;
    }
    if (ownBusiness && !inputFields.hasOwnProperty("industry")) {
      errors["industry"] = true;
    }
    if (
      ownBusiness &&
      !inputFields.hasOwnProperty("annual-revenue") &&
      businessType !== "Both"
    ) {
      errors["annual-revenue"] = true;
    }
    if (
      businessType !== "Business" &&
      businessType !== "Individual" &&
      !state.hasOwnProperty("Bookkeeping") &&
      !state.hasOwnProperty("Fractional CFO") &&
      services.length < 1
    ) {
      errors["services"] = true;
    }
    if (inputFields.hasOwnProperty("business-name")) {
      delete errors["business-name"];
    }
    if (inputFields.hasOwnProperty("industry")) {
      delete errors["industry"];
    }
    if (inputFields.hasOwnProperty("annual-revenue")) {
      delete errors["annual-revenue"];
    }
    setErrors({ ...errors });
    return Object.keys(errors).length;
  };

  function encode(data) {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  const handleChange = (e) => {
    setInputFields((inputFields) => ({
      ...inputFields,
      [e.target.name]:
        e.target.name === "please-attach-last-years-tax-return"
          ? e.target.files[0]
          : e.target.value,
    }));
  };

  const handleSelection = (label, event) => {
    setInputFields((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),

      [label]: event.target.value,
    }));
  };

  const handleCheckServices = (e) => {
    let temp = [...services] || [];

    if (e.target.checked) {
      temp = [...temp, e.target.value];
    } else {
      temp = temp.filter((val) => val !== e.target.value);
    }
    setServices(temp);
    setInputFields((inputFields) => ({
      ...inputFields,
      ["services"]: temp,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasErrors = checkForErrors();
    if (hasErrors) return;

    const form = e.target;

    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...inputFields,
        answers,
      }),
    })
      .then(
        () => {
          increaseStep();
          setState((prevState) => {
            let prevObj = { ...prevState, 5: true };
            delete prevObj[4];
            setState(prevObj);
          });
        },
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "contactFormServicePricing",
        })
      )
      .catch((error) => alert(error));
  };

  return (
    <div
      className={`form_section absolute left-0 top-[117px] w-full bg-primary-50 pb-1 lg:top-[73px] ${
        step === selectHistory.length - 2 || discoveryCallClicked
          ? "top-[69px]"
          : null
      }`}
    >
      <div className="mx-auto  w-full max-w-[1728px] px-4 pb-20 lg:px-20 lg:pb-0 xl:px-[120px]">
        <button
          onClick={() => {
            setState((prevState) => {
              const cloned = JSON.parse(JSON.stringify(prevState));
              delete cloned[4];
              return cloned;
            });
            setDiscoveryCallClicked(false);
            decreaseStep();
          }}
          className="goback-arrow-pricing-tool-button mb-8 mt-4 flex items-center gap-2 text-base font-semibold text-gray-750 no-underline hover:text-[#448E62]"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5"
              stroke="#042825"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 5L5 12L12 19"
              stroke="#042825"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
          Go Back
        </button>

        <div className=" flex flex-col justify-center gap-12 lg:flex-row">
          <div
            className={`mx-auto flex w-full max-w-[560px] flex-col rounded-xl bg-white p-10 shadow-3xl lg:mx-0`}
          >
            <h3 className="mb-4 font-heading text-2xl font-bold text-gray-750 lg:text-4xl">
              Submit Your Details
            </h3>
            <p className="mb-8 text-base font-light leading-[22px] text-gray-700 opacity-80">
              Please provide your contact information and a Dark Horse CPA will
              be in touch with you shortly.
            </p>

            <form
              name="Contact Form - Service Pricing | Cannabis"
              method="post"
              action=""
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input
                  type="hidden"
                  name="form-name"
                  value="Contact Form - Service Pricing | Cannabis"
                />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                <div hidden>
                  <label className="text-base font-semibold text-gray-750">
                    Answers
                  </label>
                  <input type="text" name="answers" />
                </div>
                <div className="mb-4">
                  <label className="text-base font-semibold text-gray-750">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="text-base font-semibold text-gray-750">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="text-base font-semibold text-gray-750">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="text-base font-semibold text-gray-750">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="phone"
                    required
                    className="mt-1 h-[56px] w-full rounded-lg border-gray-200  focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                    onChange={handleChange}
                  />
                </div>
              </>

              {businessType === "Individual" ? (
                <>
                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      Estimated Annual Income
                    </label>
                    <input
                      type="number"
                      name="estimated-annual-income"
                      required
                      className="mt-1 h-[56px] w-full rounded-lg border-gray-200  focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      State of Residency
                    </label>
                    <select
                      name="state"
                      id="0-20"
                      required
                      className="mt-1 h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0"
                      onChange={handleChange}
                    >
                      <option value="">Select One...</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </>
              ) : businessType === "Business" ||
                state.hasOwnProperty("Bookkeeping") ||
                state.hasOwnProperty("Fractional CFO") ? (
                <>
                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="business-name"
                      className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      Estimated Annual Revenue
                    </label>
                    <input
                      type="number"
                      name="estimated-annual-revenue"
                      required
                      className="mt-1 h-[56px] w-full rounded-lg border-gray-200  focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      Industry{" "}
                    </label>
                    <input
                      type="text"
                      name="industry"
                      className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-8">
                    <label className="text-base font-semibold text-gray-750">
                      State of Business Filing
                    </label>
                    <select
                      name="state-of-business-filing"
                      id="0-20"
                      required
                      className="mt-1 h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0"
                      onChange={handleChange}
                    >
                      <option value="">Select One...</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-6 mb-4 rounded-lg border-[1px] border-t-[1px] border-gray-200 bg-white px-6 lg:bg-transparent">
                    <div
                      className={`flex items-center justify-between pt-5 lg:mt-6 lg:pt-0  ${
                        ownBusiness ? " mb-5 lg:mb-4 " : "mb-5 lg:mb-6"
                      } `}
                    >
                      <h4 className="mb-0 text-base font-semibold text-gray-750">
                        Do you own a business?
                      </h4>
                      <Switch
                        checked={ownBusiness}
                        onChange={(val) => {
                          setOwnBusiness(val);
                          if (!val) {
                            delete inputFields["business-name"];
                            delete inputFields["industry"];
                            delete inputFields["annual-revenue"];
                          }
                        }}
                        className={`${
                          ownBusiness ? "bg-green-400" : "bg-gray-200"
                        } relative inline-flex  h-6 w-11 items-center rounded-full`}
                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${
                            ownBusiness
                              ? "translate-x-[22px]"
                              : "translate-x-[2px]"
                          } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                    </div>
                    <div
                      className={` ${ownBusiness ? "mb-6 block" : "hidden"}`}
                    >
                      <div className="mb-4">
                        <label className="text-base font-semibold text-gray-750">
                          Business Name{" "}
                          {errors["business-name"] && (
                            <span className="text-xs text-red-500">
                              required *
                            </span>
                          )}
                        </label>

                        <input
                          type="text"
                          name="business-name"
                          className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                          onChange={handleChange}
                        />
                      </div>

                      {businessType !== "Both" && (
                        <>
                          <p className="mb-2 mt-6 text-base !font-semibold text-gray-750 lg:font-bold">
                            Estimated Annual Revenue{" "}
                            {errors["annual-revenue"] && (
                              <span className="text-xs text-red-500">
                                required *
                              </span>
                            )}
                          </p>

                          <div className="relative mt-[54px] w-full">
                            <StyledSlider>
                              <ReactBootstrapSlider
                                name="revenue"
                                value={inputFields["annual-revenue"] || 1000}
                                step={500}
                                change={(event) =>
                                  handleSelection("annual-revenue", event)
                                }
                                orientation="horizontal"
                                ticks={[
                                  1000, 250000, 500000, 1000000, 2500000,
                                  5000000, 10000000, 100000000,
                                ]}
                                ticks_labels={[
                                  "1K",
                                  "250K",
                                  "500k",
                                  "1M",
                                  "2.5M",
                                  "5M",
                                  "10M",
                                  "100M",
                                ]}
                                ticks_positions={[
                                  0, 14.28, 28.56, 42.84, 57.14, 71.4, 85.68,
                                  100,
                                ]}
                                formatter={function (value) {
                                  return "$" + value.toLocaleString("en-US");
                                }}
                              />
                            </StyledSlider>
                          </div>
                        </>
                      )}

                      <div className="mb-4">
                        <label className="text-base font-semibold text-gray-750">
                          Industry{" "}
                          {errors["industry"] && (
                            <span className="text-xs text-red-500">
                              required *
                            </span>
                          )}
                        </label>
                        <input
                          type="text"
                          name="industry"
                          className="mt-1 h-[56px] w-full rounded-lg border-gray-200 focus:!border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-inherit"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      Are you looking for Tax, Tax Planning, Bookkeeping or
                      Fractional CFO support? (Select all that apply)
                    </label>
                  </div>

                  <div className="mb-4 inline-flex flex-col items-start space-y-4">
                    {SERVICES.map((service) => {
                      return (
                        <label className="relative cursor-pointer pl-8 text-left text-sm">
                          <input
                            type="checkbox"
                            name="services"
                            value={service}
                            onChange={handleCheckServices}
                          />
                          {service}
                          <span className="cannabis-pricing-tool-checkbox"></span>
                        </label>
                      );
                    })}
                    {errors["services"] && (
                      <p className="text-red-600">
                        Please select atleast 1 option.
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="text-base font-semibold text-gray-750">
                      State of Residency
                    </label>
                    <select
                      name="state"
                      id="0-20"
                      required
                      className="mt-1 h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0"
                      onChange={handleChange}
                    >
                      <option value="">Select One...</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </>
              )}

              {(state.hasOwnProperty("Tax Planning") ||
                state.hasOwnProperty("Tax Preparation")) && (
                <div>
                  <label className="text-base font-semibold text-gray-750">
                    Please attach last year’s tax return
                  </label>

                  <div className="mx-auto mt-1 mb-12 max-w-[500px] rounded-4xl border-2 border-dashed border-primary-500 bg-white/90 px-6 pt-7 pb-8 text-center">
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto mb-4"
                    >
                      <circle cx="32" cy="32" r="32" fill="#E9F3F5" />
                      <path
                        d="M39 31V29C39 28.4696 38.7893 27.9609 38.4142 27.5858C38.0391 27.2107 37.5304 27 37 27H29C28.4696 27 27.9609 27.2107 27.5858 27.5858C27.2107 27.9609 27 28.4696 27 29V37C27 37.5304 27.2107 38.0391 27.5858 38.4142C27.9609 38.7893 28.4696 39 29 39H31"
                        stroke="#86ACB2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23 35V35.01M33 33L42 36L38 38L36 42L33 33ZM23 23V23.01V23ZM27 23V23.01V23ZM31 23V23.01V23ZM35 23V23.01V23ZM23 27V27.01V27ZM23 31V31.01V31Z"
                        stroke="#86ACB2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="mb-6 text-lg font-bold text-gray-750">
                      Drag & Drop or upload your files
                    </div>
                    <div className="flex w-full items-center justify-center">
                      <input
                        type="file"
                        name="please-attach-last-years-tax-return"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className="flex justify-center sm:justify-start"
                onSubmit={handleSubmit}
              >
                <Button
                  text="Continue"
                  altStyle={1}
                  className="rounded-full bg-green-400 hover:!bg-[#448E62]"
                />
              </div>
            </form>
          </div>
          <div
            className={`mx-auto h-fit w-full max-w-[424px] rounded-xl bg-white p-10 shadow-3xl lg:mx-0`}
          >
            {showCustomQuote || discoveryCallClicked ? (
              <h2 className="mx-auto mb-4 flex w-fit items-end gap-2 font-body text-xl font-semibold text-gray-750 lg:text-4xl">
                Custom Quote
              </h2>
            ) : (
              <>
                <h4 className="text-center text-base font-semibold text-gray-700 opacity-50 ">
                  Your Estimate
                </h4>
                {changeCategory ? (
                  <h2 className="mx-auto mb-4 flex w-fit items-end gap-2 font-body text-xl font-semibold text-gray-750 lg:text-4xl">
                    {lowerRange === higherRange
                      ? `$${yearlyHigherRange} - $${yearlyNewHigherRange}`
                      : `$${yearlyLowerRange} - $${yearlyHigherRange}`}
                    <span className="text-primary-750 font-body text-base leading-7">
                      /year
                    </span>
                  </h2>
                ) : (
                  <h2 className="mx-auto mb-4 flex w-fit items-end gap-2 font-body text-xl font-semibold text-gray-750 lg:text-4xl">
                    {lowerRange === higherRange
                      ? `$${higherRange} - $${newHigherRange}`
                      : `$${lowerRange} - $${higherRange}`}
                    <span className="font-body text-base leading-7 text-primary-700">
                      /month
                    </span>
                  </h2>
                )}
              </>
            )}

            {state.hasOwnProperty("Bookkeeping") &&
            Object.keys(restState).length === 2 ? (
              <p className="text-center text-[14px] italic leading-[22px]">
                *A one-time startup charge equal to one month of fees may apply
                for onboarding and optimization of your account and will be
                discussed/determined by you and your CPA.
              </p>
            ) : state.hasOwnProperty("Tax Preparation") &&
              Object.keys(restState).length === 2 ? (
              <p className="text-center text-[14px] italic leading-[22px]">
                *This estimate may change based on the review of your previous
                years tax return, an understanding of your go-forward goals and
                discussion with your CPA.
              </p>
            ) : (
              <p className="text-center text-[14px] italic leading-[22px]">
                *One-time startup charge equal to one month's subscription for
                onboarding and optimizing the chart of accounts due at signing.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookConsultation;
