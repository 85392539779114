import React, { useEffect, useState } from "react";

import Button from "../Button/ButtonSolid";

import "./businesspricing.scss";

const INTERVALS = [, "Monthly", "Quarterly", "Bi-Annual", "Annually"];

const TaxPlanning = ({
  state,
  setState,
  increaseStep,
  decreaseStep,
  setSubmissionStatus,
  submissionStatus,
  customClass,
}) => {
  const { Interval: interval } = state["Tax Planning"] || {};

  const [errors, setErrors] = useState({});

  const checkForErrors = () => {
    let errors = {};
    if (!interval) {
      errors["Interval"] = true;
    }
    setErrors({ ...errors });
    return Object.keys(errors).length;
  };

  useEffect(() => {
    if (submissionStatus[0] === "submitting") {
      const hasErrors = checkForErrors();
      if (hasErrors) {
        setSubmissionStatus((statuses) => {
          const clonedStatuses = JSON.parse(JSON.stringify(statuses));
          clonedStatuses.push("errored");
          return clonedStatuses;
        });
        document
          .getElementById("Tax Planning")
          .scrollIntoView({ behavior: "smooth" });
        return;
      }
      setSubmissionStatus((statuses) => {
        const clonedStatuses = JSON.parse(JSON.stringify(statuses));
        clonedStatuses.push("success");
        return clonedStatuses;
      });
    }
  }, [submissionStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelection = (label, event) => {
    delete errors[label];
    setErrors({ ...errors });

    setState((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),
      "Tax Planning": {
        ...prevState["Tax Planning"],
        [label]: event.target.innerText,
      },
    }));
  };

  return (
    <div
      className={`tax_preparation w-full bg-primary-50 px-4 pt-4 lg:relative lg:left-auto lg:top-auto lg:mt-[56px] lg:mb-[56px] lg:h-auto lg:w-auto lg:bg-transparent lg:px-0 lg:pt-0  ${customClass} `}
    >
      <div className="h-fit pb-[100px] lg:pb-0">
        <button
          className="goback-arrow-pricing-tool-button mb-4 flex items-center gap-2 text-base font-semibold text-gray-750 no-underline hover:text-[#448E62] lg:hidden"
          onClick={() => decreaseStep()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L5 12L12 19" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{" "}
          Go Back
        </button>
        <h4
          id="Tax Planning"
          className="mb-6 hidden font-heading text-3xl font-bold text-gray-750 lg:block"
        >
          Tax Planning
        </h4>
        <h4 className="mb-10 font-heading text-3xl font-bold text-gray-750 lg:mb-6 lg:hidden">
          Add some additional details for Tax Planning
        </h4>
        <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
          How frequently would you like tax planning meetings?{" "}
          {errors["Interval"] && (
            <span className="text-xs text-red-500">required *</span>
          )}
        </p>
        <div className="preparation_text flex w-full flex-col gap-3 lg:grid lg:w-full lg:grid-cols-4 lg:gap-0 lg:rounded-lg lg:border-[1px] lg:border-gray-200">
          {INTERVALS.map((key) => {
            return (
              <button
                onClick={(event) => handleSelection("Interval", event)}
                className={`tax_panning_box_shadow mb-0 w-full cursor-pointer rounded-md  py-3 text-center  text-base no-underline hover:bg-green-400  lg:w-full lg:self-center lg:text-clip lg:rounded-none lg:border-r-[1px] lg:border-gray-200  lg:py-4 lg:px-0  lg:text-sm ${
                  interval === key
                    ? "border-[1px]  border-green-400 bg-white  font-semibold text-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d] hover:text-green-400 lg:border-0 lg:bg-green-400 lg:text-white lg:outline-0 lg:hover:text-white"
                    : "bg-white  font-normal text-gray-700 hover:text-gray-700 lg:bg-transparent lg:text-gray-300 lg:hover:text-white "
                }`}
              >
                {key}
              </button>
            );
          })}
        </div>
        <Button
          text="Continue"
          className="!relative bottom-[-80px] left-[50%] w-[93%] translate-x-[-50%] bg-green-400 hover:!bg-[#448E62] lg:hidden"
          altStyle={1}
          onClick={() => {
            const hasErrors = checkForErrors();
            if (hasErrors) return;
            increaseStep();
          }}
        />
      </div>
    </div>
  );
};

export default TaxPlanning;
