import React, { useEffect, useState } from "react";
import ReactBootstrapSlider from "react-bootstrap-slider";
import { Switch } from "@headlessui/react";
import { StyledSlider } from "./styles";
import "./businesspricing.scss";
import Button from "../Button/ButtonSolid";

const Bookkeeping = ({
  state,
  setState,
  increaseStep,
  decreaseStep,
  setSubmissionStatus,
  submissionStatus,
  customClass,
}) => {
  const ACCOUNTING_BASIS = ["Cash", "Accrual", "Not Sure"];
  const FREQUENCY = ["Monthly", "Quarterly", "Annually"];
  const CONNECTED_ACCOUNTS = ["<5", "5-9", "10-14", "15+"];
  const INTEGRATION = [
    "Square",
    "Paypal",
    "Shopify",
    "Stripe",
    "Etsy",
    "Zelle",
  ];

  const {
    "Annual Revenue": annualRevenue,
    Frequency: frequency,
    "Accounting Basis": accountingBasis,
    "Connected Accounts": connectedAccounts,
    "Bill Pay": getBillPay,
    Payroll: getPayroll,
    Integration: integration,
  } = state["Bookkeeping"] || {};

  const [errors, setErrors] = useState({});

  const [integrations, setIntegrations] = useState("");
  const [billPay, setBillPay] = useState(false);
  const [payroll, setPayroll] = useState(false);

  const checkForErrors = () => {
    let errors = {};
    if (!annualRevenue) {
      errors["Annual Revenue"] = true;
    }
    if (!accountingBasis) {
      errors["Accounting Basis"] = true;
    }
    if (!connectedAccounts) {
      errors["Connected Accounts"] = true;
    }
    if (billPay && !getBillPay) {
      errors["Bill Pay"] = true;
    }
    if (payroll && !getPayroll) {
      errors["Payroll"] = true;
    }
    if (!frequency) {
      errors["Frequency"] = true;
    }
    setErrors({ ...errors });
    return Object.keys(errors).length;
  };

  useEffect(() => {
    if (submissionStatus[0] === "submitting") {
      const hasErrors = checkForErrors();
      if (hasErrors) {
        setSubmissionStatus((statuses) => {
          const clonedStatuses = JSON.parse(JSON.stringify(statuses));
          clonedStatuses.push("errored");
          return clonedStatuses;
        });
        document
          .getElementById("Bookkeeping")
          .scrollIntoView({ behavior: "smooth" });
        return;
      }
      setSubmissionStatus((statuses) => {
        const clonedStatuses = JSON.parse(JSON.stringify(statuses));
        clonedStatuses.push("success");
        return clonedStatuses;
      });
    }
  }, [submissionStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelection = (label, event) => {
    delete errors[label];
    setErrors({ ...errors });

    setState((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),
      Bookkeeping: {
        ...prevState["Bookkeeping"],
        [label]:
          event.target?.name === "select" || label === "Annual Revenue"
            ? event.target.value
            : event.target.innerText,
      },
    }));
  };

  const handleChange = (label, event) => {
    let temp = [...integrations] || [];
    if (event.target.checked) {
      temp = [...temp, event.target.value];
    } else {
      temp = temp.filter((val) => val !== event.target.value);
    }
    setIntegrations(temp);

    setState((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),
      Bookkeeping: {
        ...prevState["Bookkeeping"],
        [label]: temp,
      },
    }));
  };

  return (
    <div
      className={`tax_preparation w-full bg-primary-50 px-4 pt-4 lg:relative lg:top-auto lg:left-auto lg:mt-[56px] lg:mb-[56px] lg:h-auto lg:w-auto lg:bg-transparent lg:px-0  lg:pt-0 ${customClass} `}
    >
      <div className="h-fit pb-[100px] lg:pb-0">
        <button
          className="goback-arrow-pricing-tool-button mb-4  flex items-center gap-2 text-base font-semibold text-gray-750 no-underline hover:text-[#448E62] lg:hidden"
          onClick={() => decreaseStep()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L5 12L12 19" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{" "}
          Go Back
        </button>
        <h4
          id="Bookkeeping"
          className="mb-10 font-heading text-3xl font-bold text-gray-750 lg:mb-6 lg:hidden"
        >
          Add some additional details for Bookkeeping
        </h4>

        <h4
          id="Bookkeeping"
          className="mb-6 hidden font-heading text-3xl font-bold text-gray-750 lg:block"
        >
          Bookkeeping
        </h4>

        <p className="mb-2 mt-6 text-base font-semibold text-gray-700 lg:font-bold">
          Annual Revenue{" "}
          {errors["Annual Revenue"] && (
            <span className="text-xs text-red-500">required *</span>
          )}
        </p>
        <div className="mt-[54px] w-full">
          <StyledSlider>
            <ReactBootstrapSlider
              value={state["Bookkeeping"]?.["Annual Revenue"] || 1000}
              step={500}
              change={(event) => handleSelection("Annual Revenue", event)}
              orientation="horizontal"
              ticks={[
                1000, 250000, 500000, 1000000, 2500000, 5000000, 10000000,
                25000000,
              ]}
              ticks_labels={[
                "1K",
                "250K",
                "500k",
                "1M",
                "2.5M",
                "5M",
                "10M",
                "25M+",
              ]}
              ticks_positions={[
                0, 14.28, 28.56, 42.84, 57.14, 71.4, 85.68, 100,
              ]}
              formatter={function (value) {
                return "$" + value.toLocaleString("en-US");
              }}
            />
          </StyledSlider>
        </div>

        <div className="lg:mb-6">
          <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
            Basis of accounting{" "}
            {errors["Accounting Basis"] && (
              <span className="text-xs text-red-500">required *</span>
            )}
          </p>
          <div className="preparation_text hidden w-full grid-cols-3 rounded-lg border-[1px] border-gray-200 lg:grid">
            {ACCOUNTING_BASIS.map((data) => {
              return (
                <button
                  onClick={(event) =>
                    handleSelection("Accounting Basis", event)
                  }
                  className={`mb-0 w-full cursor-pointer self-center border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                    accountingBasis === data
                      ? "border-[1px]  border-green-400 bg-white  font-semibold text-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d] hover:text-green-400 lg:border-0 lg:bg-green-400 lg:text-white lg:outline-0 lg:hover:text-white"
                      : "font-normal text-gray-300 hover:text-white"
                  }`}
                >
                  {data}
                </button>
              );
            })}
          </div>

          <select
            onChange={(e) => handleSelection("Accounting Basis", e)}
            name="select"
            id="0-20"
            value={accountingBasis}
            className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
              errors["Accounting Basis"] && "border border-red-500"
            } lg:hidden`}
          >
            <option value="" disabled selected>
              e.g. Cash
            </option>
            {ACCOUNTING_BASIS.map((data) => {
              return <option value={data}>{data}</option>;
            })}
          </select>
        </div>

        <div>
          <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
            How often do you need your books updated?{" "}
            {errors["Frequency"] && (
              <span className="text-xs text-red-500">required *</span>
            )}
          </p>
          <div className="preparation_text hidden w-full grid-cols-3 rounded-lg border-[1px] border-gray-200 lg:grid">
            {FREQUENCY.map((data) => {
              return (
                <button
                  onClick={(event) => handleSelection("Frequency", event)}
                  className={`mb-0 w-full cursor-pointer self-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                    frequency === data
                      ? "border-[1px]  border-green-400 bg-white font-semibold text-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d] hover:text-green-400 lg:border-0 lg:bg-green-400 lg:text-white lg:outline-0 lg:hover:text-white"
                      : "font-normal text-gray-300 hover:text-white"
                  } `}
                >
                  {data}
                </button>
              );
            })}
          </div>
          <select
            onChange={(event) => handleSelection("Frequency", event)}
            name="select"
            id="0-20"
            value={frequency}
            className={`h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0${
              errors["Frequency"] && "border border-red-500"
            } lg:hidden`}
          >
            <option value="" disabled selected>
              e.g. Quarterly
            </option>
            {FREQUENCY.map((data) => {
              return <option value={data}>{data}</option>;
            })}
          </select>

          <p className="mb-2 mt-6 text-base font-bold text-gray-700 lg:mb-3">
            How many connected accounts (banks, credit cards etc) do you
            have/use?{" "}
            {errors["Connected Accounts"] && (
              <span className="text-xs text-red-500">required *</span>
            )}
          </p>
          <div
            className="preparation_text
                     mb-6 hidden w-full grid-cols-4 rounded-lg border-[1px] border-gray-200 lg:grid"
          >
            {CONNECTED_ACCOUNTS.map((key) => {
              return (
                <button
                  onClick={(event) =>
                    handleSelection("Connected Accounts", event)
                  }
                  className={`tax_panning_box_shadow mb-0 w-full cursor-pointer rounded-md  py-3 text-center text-base  no-underline hover:bg-green-400 lg:w-full lg:self-center lg:text-clip lg:rounded-none lg:border-r-[1px] lg:border-gray-200  lg:py-4 lg:px-0  lg:text-sm ${
                    connectedAccounts === key
                      ? "border-[1px]  border-green-400 bg-white  font-semibold text-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d] hover:text-green-400 lg:border-0 lg:bg-green-400 lg:text-white lg:outline-0 lg:hover:text-white"
                      : "bg-white  font-normal text-gray-700 hover:text-gray-700 lg:bg-transparent lg:text-gray-300 lg:hover:text-white "
                  }`}
                >
                  {key}
                </button>
              );
            })}
          </div>

          <select
            onChange={(event) => handleSelection("Connected Accounts", event)}
            name="select"
            defaultValue={connectedAccounts}
            id="0-20"
            className={`h-[56px] w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] lg:hidden focus:ring-0${
              errors["Connected Accounts"] && " !border-red-600"
            }`}
          >
            <option value="" disabled selected>
              e.g. 5-9
            </option>
            {CONNECTED_ACCOUNTS.map((key) => {
              return <option value={key}>{key}</option>;
            })}
          </select>

          <div className="mt-8 rounded-lg border-[1px] border-t-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:bg-transparent">
            <div
              className={`flex items-center justify-between pt-5 lg:mt-6 lg:pt-0  ${
                billPay ? " mb-5 lg:mb-4 " : "mb-5 lg:mb-6"
              } `}
            >
              <h4 className="mb-0 text-base font-semibold text-gray-750">
                Bill Pay / Invoicing Clients{" "}
                {errors["Bill Pay"] && (
                  <span className="text-xs text-red-500">required *</span>
                )}
              </h4>
              <Switch
                checked={billPay}
                onChange={(val) => {
                  setBillPay(val);
                  if (!val) {
                    setState((prevState) => {
                      const cloned = JSON.parse(JSON.stringify(prevState));
                      delete cloned["Bookkeeping"]?.["Bill Pay"];
                      delete errors["Bill Pay"];
                      return cloned;
                    });
                  }
                  if (val) {
                    errors["Bill Pay"] = true;
                  }
                }}
                className={`${
                  billPay ? "bg-green-400" : "bg-gray-200"
                } relative inline-flex  h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${
                    billPay ? "translate-x-[22px]" : "translate-x-[2px]"
                  } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <div className={` ${billPay ? "mb-6 block" : "hidden"}`}>
              <p className="mb-1 text-base font-normal text-gray-700">
                Average number of invoices or bills each month
              </p>
              <select
                name="select"
                id="0-20"
                defaultValue={["Bill Pay"]}
                className="w-full rounded-lg border-[1px]  border-gray-200 outline-0 focus:border-green-400  focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 lg:max-w-[240px]"
                onChange={(event) => handleSelection("Bill Pay", event, true)}
              >
                <option value="" disabled selected={billPay ? true : false}>
                  Select One
                </option>
                <option value="0-20">0-20</option>
                <option value="21-40">21-40</option>
                <option value="41-60">41-60</option>
                <option value="61+">61+</option>
              </select>
            </div>
          </div>

          <div className="mt-8 rounded-lg border-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:bg-transparent">
            <div
              className={`flex items-center justify-between pt-5 lg:mt-6 lg:pt-0  ${
                billPay ? " mb-5 lg:mb-4 " : "mb-5 lg:mb-6"
              } `}
            >
              <h4 className="mb-0 text-base font-semibold text-gray-750">
                Payroll{" "}
                {errors["Payroll"] && (
                  <span className="text-xs text-red-500">required *</span>
                )}
              </h4>
              <Switch
                checked={payroll}
                onChange={(val) => {
                  setPayroll(val);
                  if (!val) {
                    setState((prevState) => {
                      const cloned = JSON.parse(JSON.stringify(prevState));
                      delete cloned["Bookkeeping"]?.["Payroll"];
                      delete errors["Payroll"];
                      return cloned;
                    });
                  }
                  if (val) {
                    errors["Payroll"] = true;
                  }
                }}
                className={`${
                  payroll ? "bg-green-400" : "bg-gray-200"
                } relative inline-flex  h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${
                    payroll ? "translate-x-[22px]" : "translate-x-[2px]"
                  } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <div className={` ${payroll ? "mb-6 block" : "hidden"}`}>
              <p className="mb-1 text-base font-normal text-gray-700">
                Number of employees
              </p>
              <select
                name="select"
                id="0-20"
                onChange={(event) => handleSelection("Payroll", event, true)}
                defaultValue={["Payroll"]}
                className="w-full rounded-lg border-[1px] border-gray-200 outline-0 focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 lg:max-w-[240px]"
              >
                <option value="" disabled selected={payroll ? true : false}>
                  Select One
                </option>
                <option value="0-20">0-20</option>
                <option value="21-40">21-40</option>
                <option value="41-60">41-60</option>
                <option value="61+">61+</option>
              </select>
            </div>
          </div>

          <div className=" mt-6 lg:mb-6">
            <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
              What integrations (if any) do you have with your bookkeeping
              software?{" "}
            </p>
            <div className="preparation_text flex w-full flex-col  justify-between lg:flex-row">
              {INTEGRATION.map((data) => {
                return (
                  <div
                    className={`mb-0 cursor-pointer py-4 text-sm no-underline ${
                      integration === data
                        ? "bg-white  font-semibold text-green-400 outline-[3px] outline-offset-0 outline-[#55b27a4d] hover:text-green-400 lg:border-0 lg:bg-green-400 lg:text-white lg:outline-0 lg:hover:text-white"
                        : "font-normal text-gray-300 hover:text-gray-300"
                    }`}
                  >
                    <label
                      for={data}
                      className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base"
                    >
                      <input
                        type="checkbox"
                        id={data}
                        value={data}
                        onChange={(event) => handleChange("Integration", event)}
                        name={data}
                      />
                      <span className="text-sm">{data}</span>
                      <span className="cannabis-pricing-tool-checkbox"></span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Button
          text="Continue"
          className="!relative bottom-[-80px] left-[50%] w-[93%] translate-x-[-50%] bg-green-400 hover:!bg-[#448E62] lg:hidden"
          altStyle={1}
          onClick={() => {
            const hasErrors = checkForErrors();
            if (hasErrors) return;
            increaseStep();
          }}
        />
      </div>
    </div>
  );
};

export default Bookkeeping;
