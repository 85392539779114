import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import "./businesspricing.scss";
import Button from "../Button/ButtonSolid";
import toolTip from "../../images/business-pricing/tooltip-icon.svg";

const FractionalCFO = ({
  setState,
  increaseStep,
  decreaseStep,
  setSubmissionStatus,
  submissionStatus,
  customClass,
}) => {
  const [budgetCreationAndAnalysis, setBudgetCreationAndAnalysis] =
    useState(false);
  const [statementOfCashFlows, setStatementOfCashFlows] = useState(false);
  const [financialForecast, setFinancialForecast] = useState(false);
  const [internalControls, setInternalControls] = useState(false);
  const [KPIMonitoring, setKPIMonitoring] = useState(false);
  const [monthlyFluxAnalysis, setMonthlyFluxAnalysis] = useState(false);
  const [operationalProcessAdvisory, setOperationalProcessAdvisory] =
    useState(false);
  const [technologyAdvisory, setTechnologyAdvisory] = useState(false);
  const [strategicAdvisoryMeetings, setStrategicAdvisoryMeetings] =
    useState(false);

  const [errors, setErrors] = useState({});

  const checkForErrors = () => {
    let errors = {};
    if (
      budgetCreationAndAnalysis ||
      statementOfCashFlows ||
      financialForecast ||
      internalControls ||
      KPIMonitoring ||
      monthlyFluxAnalysis ||
      operationalProcessAdvisory ||
      technologyAdvisory ||
      strategicAdvisoryMeetings
    ) {
      increaseStep();
    } else {
      errors["CFO Services"] = true;
    }
    setErrors({ ...errors });
    return Object.keys(errors).length;
  };

  useEffect(() => {
    if (submissionStatus[0] === "submitting") {
      const hasErrors = checkForErrors();
      if (hasErrors) {
        setSubmissionStatus((statuses) => {
          const clonedStatuses = JSON.parse(JSON.stringify(statuses));
          clonedStatuses.push("errored");
          return clonedStatuses;
        });
        document
          .getElementById("Fractional CFO")
          .scrollIntoView({ behavior: "smooth" });
        return;
      }
      setSubmissionStatus((statuses) => {
        const clonedStatuses = JSON.parse(JSON.stringify(statuses));
        clonedStatuses.push("success");
        return clonedStatuses;
      });
    }
  }, [submissionStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelection = (label, value) => {
    setState((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),
      ["Fractional CFO"]: {
        ...prevState["Fractional CFO"],
        [label]: value,
      },
    }));
  };

  return (
    <div
      className={`tax_preparation lg:left-autolg:w-auto w-full bg-primary-50 pt-4 lg:relative lg:top-auto lg:mt-[56px] lg:mb-[56px] lg:bg-transparent lg:px-0  lg:pt-0 ${customClass}`}
    >
      <div className=" h-fit pb-[100px] lg:pb-0">
        <div className="px-4 lg:px-0">
          <button
            className="goback-arrow-pricing-tool-button mb-4  flex items-center gap-2 text-base font-semibold text-gray-750 no-underline hover:text-[#448E62] lg:hidden"
            onClick={() => decreaseStep()}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 5L5 12L12 19" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>{" "}
            Go Back
          </button>

          <h4
            id="Fractional CFO"
            className="mb-6 hidden font-heading text-3xl font-bold text-gray-750 lg:block"
          >
            Fractional CFO
          </h4>
          <h4 className="mb-10 font-heading text-3xl font-bold text-gray-750 lg:mb-6 lg:hidden">
            Add some additional details for Fractional CFO Services
          </h4>
        </div>
        <p className="mb-2 px-4 text-base font-bold text-gray-700 lg:mb-3 lg:ml-0 lg:px-0">
          Which Fractional CFO services are you interested in?{" "}
        </p>
        {errors["CFO Services"] && (
          <div className="mb-4">
            <span className="text-red-500">
              Please select atleast 1 option.
            </span>
          </div>
        )}
        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Budget Creation And Analysis
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="budgetCreationAndAnalysis"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Create a customized budget plan based on your goals and
                financial data. Our team analyzes your financial performance,
                sets realistic targets, and provides recommendations to improve
                results. We monitor progress, make adjustments as needed, and
                work to achieve budget goals.
              </p>
            </div>
            <Switch
              checked={budgetCreationAndAnalysis}
              onChange={(val) => {
                setBudgetCreationAndAnalysis(val);
                handleSelection("Budget Creation And Analysis", val);
              }}
              className={`${
                budgetCreationAndAnalysis ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  budgetCreationAndAnalysis
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>
        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Statement of Cash Flows
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="statementOfCashFlows"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Prepare a cash flow statement and forecast to provide insights
                into your cash inflows and outflows. Our team analyzes your
                financial data, identifies trends and potential gaps, and
                provides recommendations for improved cash flow management. We
                monitor progress, analyze results, and make adjustments as
                needed to help you achieve your cash flow goals.
              </p>
            </div>
            <Switch
              checked={statementOfCashFlows}
              onChange={(val) => {
                setStatementOfCashFlows(val);
                handleSelection("Statement Of Cash Flows", val);
              }}
              className={`${
                statementOfCashFlows ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  statementOfCashFlows
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>
        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Financial Forecast
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="financialForecast"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Provide valuable insights for your business's strategic planning
                and decision-making. We analyze financial data and work closely
                with you to develop a forecast that helps achieve your goals and
                objectives.
              </p>
            </div>
            <Switch
              checked={financialForecast}
              onChange={(val) => {
                setFinancialForecast(val);
                handleSelection("Financial Forecast", val);
              }}
              className={`${
                financialForecast ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  financialForecast ? "translate-x-[22px]" : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Internal Controls Design and Implementation
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="internalControls"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Review and improve your accounting practices with effective
                internal controls for accuracy, completeness, and security of
                financial information. Our team implements controls for Accounts
                Receivable, Accounts Payable, Payroll, and more.
              </p>
            </div>
            <Switch
              checked={internalControls}
              onChange={(val) => {
                setInternalControls(val);
                handleSelection(
                  "Internal Controls Design and Implementation",
                  val
                );
              }}
              className={`${
                internalControls ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  internalControls ? "translate-x-[22px]" : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              KPI Monitoring and Reporting
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="KPIMonitoring"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Identify and track key performance indicators critical to your
                business success. We establish appropriate KPIs, develop
                tracking systems, and provide regular reports with insights and
                recommendations for improvement. This service includes access to
                a company reporting dashboard with analytics of financial
                information.
              </p>
            </div>
            <Switch
              checked={KPIMonitoring}
              onChange={(val) => {
                setKPIMonitoring(val);
                handleSelection("KPI Monitoring and Reporting", val);
              }}
              className={`${
                KPIMonitoring ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  KPIMonitoring ? "translate-x-[22px]" : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Monthly Flux Analysis
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="monthlyFluxAnalysis"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Provide a detailed analysis of your monthly financial
                statements, identifying significant changes in key financial
                metrics. We provide valuable insights, highlight key trends, and
                point out anomalies to improve your financial performance based
                on the analysis.
              </p>
            </div>
            <Switch
              checked={monthlyFluxAnalysis}
              onChange={(val) => {
                setMonthlyFluxAnalysis(val);
                handleSelection("Monthly Flux Analysis", val);
              }}
              className={`${
                monthlyFluxAnalysis ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  monthlyFluxAnalysis
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Operational Process Advisory
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="operationalProcessAdvisory"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Provide a detailed analysis of your monthly financial
                statements, identifying significant changes in key financial
                metrics. We provide valuable insights, highlight key trends, and
                point out anomalies to improve your financial performance based
                on the analysis.
              </p>
            </div>
            <Switch
              checked={operationalProcessAdvisory}
              onChange={(val) => {
                setOperationalProcessAdvisory(val);
                handleSelection("Operational Process Advisory", val);
              }}
              className={`${
                operationalProcessAdvisory ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  operationalProcessAdvisory
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Technology Advisory
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="technologyAdvisory"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Provide guidance on selecting, implementing, and optimizing
                accounting technology solutions. We assess your current
                solutions, recommend best-in-class options aligned with your
                goals, and help implement and optimize them. We provide ongoing
                support and training to ensure maximum value to your business.
              </p>
            </div>
            <Switch
              checked={technologyAdvisory}
              onChange={(val) => {
                setTechnologyAdvisory(val);
                handleSelection("Technology Advisory", val);
              }}
              className={`${
                technologyAdvisory ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  technologyAdvisory
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-200 bg-white px-6 lg:mb-4 lg:rounded-lg lg:border-[1px] lg:bg-transparent">
          <div className="mb-5 flex items-center justify-between pt-5 lg:mt-6 lg:mb-4 lg:pt-0">
            <h4 className="mb-0 text-base font-semibold text-gray-750">
              Strategic Advisory Meetings
            </h4>
            <div
              onClick={(e) => e.stopPropagation()}
              className="group  relative left-2 mr-auto"
            >
              <div>
                <img
                  name="strategicAdvisoryMeetings"
                  src={toolTip}
                  className="h-[18px] w-[18px] cursor-pointer"
                />
              </div>
              <p className="tooltip_clip_path absolute bottom-[35px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-[#000] p-4 text-base font-normal text-white  lg:group-hover:block">
                Meet with you regularly to discuss and address strategic
                financial issues and concerns. We work with you to develop
                solutions aligned with your business goals, and provide expert
                advice and guidance to help you make informed financial
                decisions that drive your business's success.
              </p>
            </div>
            <Switch
              checked={strategicAdvisoryMeetings}
              onChange={(val) => {
                setStrategicAdvisoryMeetings(val);
                handleSelection("Strategic Advisory Meetings", val);
              }}
              className={`${
                strategicAdvisoryMeetings ? "bg-green-400" : "bg-gray-200"
              } relative inline-flex  h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  strategicAdvisoryMeetings
                    ? "translate-x-[22px]"
                    : "translate-x-[2px]"
                } inline-block h-5 w-5 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>

        <Button
          text="Continue"
          className="!relative bottom-[-80px] left-[50%] w-[93%] translate-x-[-50%] bg-green-400 hover:!bg-[#448E62] lg:hidden"
          altStyle={1}
          onClick={() => {
            const hasErrors = checkForErrors();
            if (hasErrors) return;
          }}
        />
      </div>
    </div>
  );
};

export default FractionalCFO;
