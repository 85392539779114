import React, { useEffect, useState } from "react";

import Button from "../Button/ButtonSolid";

import "./businesspricing.scss";

const BUSINESS_TYPES = [
  {
    name: "Individual",
    icon: null,
    hoverData: null,
  },
  {
    name: "Business",
    icon: null,
    hoverData: null,
  },
  {
    name: "Both",
    icon: null,
    hoverData: null,
  },
];

const FILLINGSTATUS = [
  "Single",
  "Head of Household",
  "Married Filing Jointly",
  "Married Filing Separately",
];

const OWNHOME = ["Yes", "No"];

const K1FORMS = ["0", "1", "2 - 5", "6 - 14", "15+"];

const STATEANTICIPATE = ["0", "1", "2 - 5", "6 - 9", "10+"];

const TAXSTRUCTURE = [
  "S Corporation",
  "C Corporation",
  "Multi-Member LLC",
  "SMLLC/Sole Proprietor",
  "Other",
];

const REVENUES = ["<$500k", "$500K - $1M", "$1M - $5M", "$5M - $25M", "$25M+"];

const SHAREHOLDERS = ["1", "2 - 5", "6 - 9", "10 - 24", "25+"];

const TaxPreparation = ({
  state,
  setState,
  increaseStep,
  decreaseStep,
  setSubmissionStatus,
  submissionStatus,
  customClass,
}) => {
  const {
    "Business Type": type,
    "Filling Status": fillingStatus,
    "Own Home": ownHome,
    "K1 Forms": k1Forms,
    "State Anticipate": stateAnticipate,
    "Tax Structure": taxStructure,
    Revenue: revenue,
    Shareholders: shareholders,
  } = state["Tax Preparation"] || {};

  const [errors, setErrors] = useState({});

  const checkForErrors = () => {
    let errors = {};
    if (type !== "Business" && !fillingStatus) {
      errors["Filling Status"] = true;
    }
    if (!type) {
      errors["Business Type"] = true;
    }
    if (type !== "Business" && !ownHome) {
      errors["Own Home"] = true;
    }
    if (type !== "Business" && !k1Forms) {
      errors["K1 Forms"] = true;
    }
    if (type !== "Business" && !stateAnticipate) {
      errors["State Anticipate"] = true;
    }
    if (type !== "Individual" && !taxStructure) {
      errors["Tax Structure"] = true;
    }
    if (type !== "Individual" && !revenue) {
      errors["Revenue"] = true;
    }
    if (type !== "Individual" && !shareholders) {
      errors["Shareholders"] = true;
    }
    setErrors({ ...errors });
    return Object.keys(errors).length;
  };

  useEffect(() => {
    if (submissionStatus[0] === "submitting") {
      const hasErrors = checkForErrors();
      if (hasErrors) {
        setSubmissionStatus((statuses) => {
          const clonedStatuses = JSON.parse(JSON.stringify(statuses));
          clonedStatuses.push("errored");
          return clonedStatuses;
        });
        document
          .getElementById("Tax Preparation")
          .scrollIntoView({ behavior: "smooth" });
        return;
      }
      setSubmissionStatus((statuses) => {
        const clonedStatuses = JSON.parse(JSON.stringify(statuses));
        clonedStatuses.push("success");
        return clonedStatuses;
      });
    }
  }, [submissionStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelection = (label, event) => {
    delete errors[label];
    setErrors({ ...errors });

    setState((prevState) => ({
      ...JSON.parse(JSON.stringify(prevState)),
      "Tax Preparation": {
        ...prevState["Tax Preparation"],
        [label]:
          event.target?.name === "select"
            ? event.target.value
            : event.target.innerText,
      },
    }));
  };

  return (
    <div
      className={`tax_preparation w-full bg-primary-50 px-4 pt-4 lg:relative lg:top-auto lg:left-auto lg:mb-[56px] lg:h-auto lg:w-auto lg:bg-transparent lg:px-0 lg:pt-0 ${customClass}`}
    >
      <div className="h-fit pb-[100px] lg:pb-0">
        <button
          className="goback-arrow-pricing-tool-button flex items-center gap-2 text-base font-semibold text-gray-750 no-underline hover:text-[#448E62] lg:hidden"
          onClick={() => decreaseStep()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L5 12L12 19" stroke="#042825" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{" "}
          Go Back
        </button>
        <div className="mt-4 lg:mt-12">
          <h4
            id="Tax Preparation"
            className="mb-6 hidden font-heading text-3xl font-bold text-gray-750 lg:block"
          >
            Tax Preparation
          </h4>
          <h4 className="mb-10 font-heading text-3xl font-bold text-gray-750 lg:mb-6 lg:hidden">
            Add some additional details for Tax Preparation
          </h4>
          <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
            What Type of Tax Preparation Are You Looking For?{" "}
            {errors["Business Type"] && (
              <span className="text-xs text-red-500">required *</span>
            )}
          </p>
          <div className="preparation_text  mb-6 hidden w-full grid-cols-3 rounded-lg border-[1px] border-gray-200 lg:grid">
            {BUSINESS_TYPES.map(({ name, icon, hoverData }) => {
              return (
                <button
                  className={`relative flex w-full cursor-pointer items-center justify-center gap-1 border-r-[1px] border-gray-200 text-left text-sm font-normal no-underline   hover:bg-green-400 ${
                    type === name ? "bg-green-400" : "bg-transparent"
                  }`}
                  onClick={(event) => handleSelection("Business Type", event)}
                >
                  <p
                    className={`mb-0 w-full py-4 text-center text-sm hover:text-white ${
                      type === name
                        ? "font-medium text-white "
                        : "font-normal text-gray-300"
                    } transition-all duration-300 ease-linear `}
                  >
                    {name}
                  </p>
                  {icon && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="top-[50% ] group absolute translate-x-[-50%] lg:right-0 xl:right-2"
                    >
                      <img src={icon} className="h-4 w-4" />
                      <p className="tooltip_clip_path absolute bottom-[30px] left-[50%] z-10 mb-0 hidden w-[296px] translate-x-[-50%] rounded-lg bg-primary-900 p-4 text-base font-normal text-white group-hover:block">
                        {hoverData}
                      </p>
                    </div>
                  )}
                </button>
              );
            })}
          </div>
          <select
            name="select"
            id="option"
            value={type}
            className={`mb-6 h-[56px] w-full rounded-lg border-[1px] focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0  ${
              errors["Business Type"] && "border border-red-500"
            } border-gray-200 outline-0 lg:hidden`}
            onChange={(e) => handleSelection("Business Type", e)}
          >
            <option value="" disabled selected>
              e.g. Individual
            </option>
            <option value="Individual">Individual</option>
            <option value="Business">Business</option>
            <option value="Both">Both</option>
          </select>

          {(type === "Individual" || type === "Both") && (
            <>
              <div>
                <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:font-bold">
                  What is your filing status?{" "}
                  {errors["Filling Status"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-4 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {FILLINGSTATUS.map((status) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("Filling Status", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 hover:text-white ${
                          fillingStatus === status
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {status}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={fillingStatus}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["Filling Status"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("Filling Status", e)}
                >
                  <option value="" disabled selected>
                    e.g. Single
                  </option>
                  <option value="Single">{`Single`}</option>
                  <option value="Head of Household">Head of Household</option>
                  <option value="Married Filing Jointly">
                    Married Filing Jointly
                  </option>
                  <option value="Married Filing Separately">
                    Married Filing Separately
                  </option>
                </select>
              </div>
              <div>
                <p className="mb-2  text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  Do you own your home?{" "}
                  {errors["Own Home"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-2 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {OWNHOME.map((home) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("Own Home", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          ownHome === home
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {home}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={ownHome}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["Own Home"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("Own Home", e)}
                >
                  <option value="" disabled selected>
                    e.g. Yes
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <p className="mb-2  text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  How many Forms K-1 do you expect to receive this year?{" "}
                  {errors["K1 Forms"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-5 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {K1FORMS.map((forms) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("K1 Forms", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          k1Forms === forms
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {forms}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={k1Forms}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["K1 Forms"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("K1 Forms", e)}
                >
                  <option value="" disabled selected>
                    e.g. "2 - 5"
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2 - 5">2 - 5</option>
                  <option value="6 - 14">6 - 14</option>
                  <option value="15+">15+</option>
                </select>
              </div>
              <div>
                <p className="mb-2  text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  How many states do you anticipate filing in?{" "}
                  {errors["State Anticipate"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-5 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {STATEANTICIPATE.map((state) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("State Anticipate", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          stateAnticipate === state
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {state}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={stateAnticipate}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["State Anticipate"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("State Anticipate", e)}
                >
                  <option value="" disabled selected>
                    e.g. "2 - 5"
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2 - 5">2 - 5</option>
                  <option value="6 - 9">6 - 9</option>
                  <option value="10+">10+</option>
                </select>
              </div>{" "}
            </>
          )}

          {(type === "Business" || type === "Both") && (
            <>
              <div>
                <p className="mb-2  text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  What is the tax structure of your entity?{" "}
                  {errors["Tax Structure"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-5 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {TAXSTRUCTURE.map((tax) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("Tax Structure", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          taxStructure === tax
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {tax}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={taxStructure}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["Tax Structure"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("Tax Structure", e)}
                >
                  <option value="" disabled selected>
                    e.g. "S Corporation"
                  </option>
                  <option value="S Corporation">S Corporation</option>
                  <option value="C Corporation">C Corporation</option>
                  <option value="Multi-Member LLC">Multi-Member LLC</option>
                  <option value="SMLLC/Sole Proprietor">
                    SMLLC/Sole Proprietor
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <p className="mb-2 text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  What was your gross revenue last year?{" "}
                  {errors["Revenue"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-5 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {REVENUES.map((rev) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("Revenue", e)}
                        className={`mb-0 w-full cursor-pointer justify-center border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          revenue === rev
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {rev}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={revenue}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["Revenue"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("Revenue", e)}
                >
                  <option value="" disabled selected>
                    e.g. $1M - $5M
                  </option>
                  <option value="<$500k">{`<$500k`}</option>
                  <option value="$500K - $1M">$500K - $1M</option>
                  <option value="$1M - $5M">$1M - $5M</option>
                  <option value="$5M - $25M">$5M - $25M</option>
                  <option value="$25M+">$25M+</option>
                </select>
              </div>
              <div>
                <p className="mb-2  text-base font-semibold text-gray-700 lg:mb-3 lg:mt-6 lg:font-bold">
                  How many shareholders/members/partners are there (including
                  you)?{" "}
                  {errors["Shareholders"] && (
                    <span className="text-xs text-red-500">required *</span>
                  )}
                </p>
                <div className=" preparation_text hidden w-full grid-cols-5 rounded-lg border-[1px] border-gray-200 lg:grid">
                  {SHAREHOLDERS.map((shareHol) => {
                    return (
                      <button
                        onClick={(e) => handleSelection("Shareholders", e)}
                        className={`mb-0 w-full cursor-pointer justify-center  border-r-[1px] border-gray-200 py-4 text-sm no-underline hover:bg-green-400 ${
                          shareholders === shareHol
                            ? "bg-green-400 font-medium text-white hover:text-white"
                            : "font-normal text-gray-300 hover:text-white"
                        } `}
                      >
                        {shareHol}
                      </button>
                    );
                  })}
                </div>
                <select
                  name="select"
                  id="option"
                  value={shareholders}
                  className={`mb-6 h-[56px] w-full rounded-lg border-[1px] border-gray-200  focus:border-green-400 focus:outline-[3px] focus:outline-offset-0 focus:outline-[#55b27a4d] focus:ring-0 ${
                    errors["Shareholders"] && "border border-red-500"
                  } outline-0 lg:hidden`}
                  onChange={(e) => handleSelection("Shareholders", e)}
                >
                  <option value="" disabled selected>
                    e.g. "2 - 5"
                  </option>
                  <option value="1">1</option>
                  <option value="2 - 5">2 - 5</option>
                  <option value="6 - 9">6 - 9</option>
                  <option value="10 - 24">10 - 24</option>
                  <option value="25+">25+</option>
                </select>
              </div>{" "}
            </>
          )}
        </div>
        <Button
          text="Continue"
          className="!relative bottom-[-80px] left-[50%] w-[93%] translate-x-[-50%] bg-green-400 hover:!bg-[#448E62] lg:hidden"
          altStyle={1}
          onClick={() => {
            const hasErrors = checkForErrors();
            if (hasErrors) return;
            increaseStep();
          }}
        />
      </div>
    </div>
  );
};

export default TaxPreparation;
